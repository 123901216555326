import React from 'react'
import {
  TextField,
  TextInput,
  BooleanField,
  NullableBooleanInput,
} from 'react-admin'
import { makeStyles } from '@material-ui/core/styles'
import { booleanFieldOptions } from '../../util/component-options'
import { Datagrid, List, Filter } from '../../custom'
import { useLabel, useAddressFields } from '../../../hooks'

const CompaniesFilter = props => {
  const label = useLabel(props)

  return (
    <Filter>
      <TextInput label={label('name', 'filter')} source='name_i_cont' />
      <NullableBooleanInput label={label('is_active')} source='is_active_true' />
    </Filter>
  )
}

const useStyles = makeStyles(
  {
    booleanFieldHeader: {
      textAlign: 'center',
    },
  },
)

const CompaniesList = props => {
  const label = useLabel(props)
  const addressFields = useAddressFields('billing_address')
  const classes = useStyles(props)

  return (
    <List
      {...props}
      filters={<CompaniesFilter />}
      filterDefaultValues={{ is_active_true: true }}
    >
      <Datagrid>
        <TextField source='name' label={label('name')} />
        <TextField source='ledger_code' label={label('ledger_code')} />
        {
          addressFields.map(({ source, label: _label }) => {
            return (
              <TextField key={source} source={source} sortBy={source} label={label(_label)} />
            )
          })
        }
        <TextField source='federal_id_number' label={label('federal_id_number')} />
        <BooleanField {...booleanFieldOptions} source='is_active' label={label('is_active')} headerClassName={classes.booleanFieldHeader} />
      </Datagrid>
    </List>
  )
}

export default CompaniesList
